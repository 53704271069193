<template>
  <el-dialog
    class="grade-dialog"
    :title="`${preTitle}专业`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="520px"
    :before-close="cancel"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="专业名称" prop="majorName">
        <el-input v-model.trim="formData.majorName" />
      </el-form-item>
      <el-form-item label="首年学费/元" prop="amount">
        <el-input v-model.trim="formData.amount" />
      </el-form-item>
      <el-form-item label="专业类型">
        <el-select v-model="formData.majorType">
          <el-option
            v-for="{paramName,paramValue} in majorTypes"
            :label="paramName"
            :value="paramValue"
            :key="paramValue"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="学制">
        <el-select v-model="formData.lengthOfSchooling" clearable>
          <el-option
            v-for="{paramName,paramValue} in $store.state.systemParam.lengthOfSchoolingType"
            :key="paramValue"
            :label="paramName"
            :value="paramValue" />
        </el-select>
      </el-form-item>
      <el-form-item label="可转专业">
        <el-radio-group v-model="formData.canChange">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属院系" prop="collegeId">
        <el-select v-model="formData.collegeId" :disabled="!!$store.state.login.userInfo.collegeId" filterable>
          <el-option
            v-for="{collegeName, id} in collegeList"
            :label="collegeName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否全日制" prop="collegeId">
        <el-select v-model="formData.partTimeFlag">
          <el-option :value="0" label="全日制" />
          <el-option :value="1" label="非全日制" />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click='save("专业",addMajor)'>确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addMajor, getMajorById } from '@/api/major'
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'addMajor',
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: '',
        amount: '', // 首年学费
        // artFlag: 0, // 是否为艺术类 1 是, 0 否
        majorType: 'normal', // 专业类型
        canChange: 1, // 是否为可转专业 1 是, 0 否
        // campusId: '', // 校区
        collegeId: this.$store.state.login.userInfo.collegeId || '', // 院系
        partTimeFlag: 0,
        majorName: '', // 专业名称
        remarks: '',
        lengthOfSchooling: ''
      },
      campusList: [],
      collegeList: [],
      majorTypes: [],
      rules: {
        majorName: [
          {
            required: true,
            message: '请输入专业名',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        amount: this.$store.state.commonValidate.validatorTwoDecimalPlacesObj,
        // campusId: [{ required: true, message: '请选择校区', trigger: 'blur' }],
        collegeId: [{
          required: true,
          message: '请选择院系',
          trigger: 'blur'
        }]
      }
    }
  },
  mounted () {
    this.majorTypes = this.$store.state.systemParam.majorType
    this.getCollegeListQuery()
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getMajorById)
      }
    })
  },
  methods: {
    addMajor, // 保存api
    // 根据校区获取院系
    getCollegeListQuery () {
      this.$http.getCollegeListQuery({}).then((res) => {
        this.collegeList = res.data
      })
    }
  }
}
</script>
