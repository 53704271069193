<template>
  <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <view-search-form ref="searchFormRef" keyword-placeholder="专业名称" :queryInfo.sync="queryInfo"
        :tool-list="['partTimeFlag', 'college', 'keyword']" :insert-select-all="['college']"
        @on-search="renderTable(1)" />
      <div class="header-button">
        <el-button @click="exportExcel()" type="success" size="small">
          按条件导出
        </el-button>
        <upload-excel :permit-key="['basic:major:edit']" :loading.sync="loading" :api="$http.importExcelHttp.importMajor"
          @on-download="$http.exportExcelHttp.exportMajorTemplate()" @on-success="renderTable(1)" />
        <el-button v-permission="['basic:major:edit']" type="primary" size="mini" @click="showDialog()">添加专业
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="majorName" label="专业名称" width="250" />
      <el-table-column label="专业类型" width="100">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.majorType, $store.state.systemParam.majorType) }}
        </template>
      </el-table-column>
      <el-table-column prop="canChange" label="可转专业" width="100" align="center">
        <template v-slot="{ row }">
          <el-tag type="success" v-if="Number(row.canChange) === 1">是
          </el-tag>
          <el-tag type="danger" v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="所属院系" width="200" />
      <el-table-column prop="lengthOfSchooling" width="100" label="学制" align="center">
        <template v-slot="{ row }">
          {{ row.lengthOfSchooling || row.lengthOfSchooling === 0 ?
            $store.state.systemParam.lengthOfSchoolingType[Number(row.lengthOfSchooling) - 2].paramName : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column prop="remarks" min-width="100" label="备注" />
      <el-table-column label="操作" width="220" fixed="right">
        <template v-slot="{ row }">
          <el-button v-permission="['basic:major:edit']" type="primary" size="mini"
            @click="showSubDialog('visibleBindCampusDialog', 'bindCampusDialogRef', row.id, row.majorName)">校区管理
          </el-button>
          <el-button v-permission="['basic:major:edit']" type="primary" size="mini" @click="showDialog(row.id)">编辑
          </el-button>
          <el-button v-permission="['basic:major:del']" size="mini" type="danger"
            @click="del(deleteMajor, row.id, `专业-${row.majorName}`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--  编辑/添加 dialog  -->
    <add-major ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
    <!--  编辑校区 dialog  -->
    <major-bind-campus-dialog ref="bindCampusDialogRef" :visible.sync="visibleBindCampusDialog"
      v-if="visibleBindCampusDialog" />
  </table-view>
</template>

<script>
import { getMajorList, deleteMajor } from '@/api/major'
import tableView from '@/vue/mixins/table-view'
import AddMajor from '@/views/pages/baseInfo/major/dialog/addMajor.vue'
import MajorBindCampusDialog from '@/views/pages/baseInfo/major/dialog/majorBindCampusDialog.vue'

export default {
  components: {
    MajorBindCampusDialog,
    AddMajor
  },
  mixins: [tableView],
  data() {
    return {
      visibleBindCampusDialog: false,
      queryInfo: {
        partTimeFlag: null,
        campusId: null,
        collegeId: this.$store.state.login.userInfo.collegeId || null
      }
    }
  },
  methods: {
    deleteMajor, // 删除api
    // 获取专业列表
    renderTable(pageNum) {
      this.getTableData(getMajorList, pageNum)
    },
    // 导出
    exportExcel() {
      this.$http.exportExcelHttp.exportMajorCampus(this.queryInfo)
    }
  }
}
</script>
