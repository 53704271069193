var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"keyword-placeholder":"专业名称","queryInfo":_vm.queryInfo,"tool-list":['partTimeFlag', 'college', 'keyword'],"insert-select-all":['college']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}}),_c('div',{staticClass:"header-button"},[_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v(" 按条件导出 ")]),_c('upload-excel',{attrs:{"permit-key":['basic:major:edit'],"loading":_vm.loading,"api":_vm.$http.importExcelHttp.importMajor},on:{"update:loading":function($event){_vm.loading=$event},"on-download":function($event){return _vm.$http.exportExcelHttp.exportMajorTemplate()},"on-success":function($event){return _vm.renderTable(1)}}}),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['basic:major:edit']),expression:"['basic:major:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v("添加专业 ")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业名称","width":"250"}}),_c('el-table-column',{attrs:{"label":"专业类型","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.majorType, _vm.$store.state.systemParam.majorType))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"canChange","label":"可转专业","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(Number(row.canChange) === 1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("是 ")]):_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("否")])]}}])}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"所属院系","width":"200"}}),_c('el-table-column',{attrs:{"prop":"lengthOfSchooling","width":"100","label":"学制","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lengthOfSchooling || row.lengthOfSchooling === 0 ? _vm.$store.state.systemParam.lengthOfSchoolingType[Number(row.lengthOfSchooling) - 2].paramName : '-')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","min-width":"100","label":"备注"}}),_c('el-table-column',{attrs:{"label":"操作","width":"220","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['basic:major:edit']),expression:"['basic:major:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showSubDialog('visibleBindCampusDialog', 'bindCampusDialogRef', row.id, row.majorName)}}},[_vm._v("校区管理 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['basic:major:edit']),expression:"['basic:major:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id)}}},[_vm._v("编辑 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['basic:major:del']),expression:"['basic:major:del']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(_vm.deleteMajor, row.id, ("专业-" + (row.majorName)))}}},[_vm._v("删除 ")])]}}])})],1),(_vm.visibleDialog)?_c('add-major',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleBindCampusDialog)?_c('major-bind-campus-dialog',{ref:"bindCampusDialogRef",attrs:{"visible":_vm.visibleBindCampusDialog},on:{"update:visible":function($event){_vm.visibleBindCampusDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }