var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"grade-dialog",attrs:{"title":(_vm.preTitle + "专业"),"visible":_vm.show,"close-on-click-modal":false,"width":"520px","before-close":_vm.cancel},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('cancel-popover',{ref:"cancelRef",attrs:{"disabled":_vm.loadData.loading,"update-flag":_vm.updateFlag},on:{"update:disabled":function($event){return _vm.$set(_vm.loadData, "loading", $event)}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save("专业",_vm.addMajor)}}},[_vm._v("确 定")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"专业名称","prop":"majorName"}},[_c('el-input',{model:{value:(_vm.formData.majorName),callback:function ($$v) {_vm.$set(_vm.formData, "majorName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.majorName"}})],1),_c('el-form-item',{attrs:{"label":"首年学费/元","prop":"amount"}},[_c('el-input',{model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.amount"}})],1),_c('el-form-item',{attrs:{"label":"专业类型"}},[_c('el-select',{model:{value:(_vm.formData.majorType),callback:function ($$v) {_vm.$set(_vm.formData, "majorType", $$v)},expression:"formData.majorType"}},_vm._l((_vm.majorTypes),function(ref){
var paramName = ref.paramName;
var paramValue = ref.paramValue;
return _c('el-option',{key:paramValue,attrs:{"label":paramName,"value":paramValue}})}),1)],1),_c('el-form-item',{attrs:{"label":"学制"}},[_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.formData.lengthOfSchooling),callback:function ($$v) {_vm.$set(_vm.formData, "lengthOfSchooling", $$v)},expression:"formData.lengthOfSchooling"}},_vm._l((_vm.$store.state.systemParam.lengthOfSchoolingType),function(ref){
var paramName = ref.paramName;
var paramValue = ref.paramValue;
return _c('el-option',{key:paramValue,attrs:{"label":paramName,"value":paramValue}})}),1)],1),_c('el-form-item',{attrs:{"label":"可转专业"}},[_c('el-radio-group',{model:{value:(_vm.formData.canChange),callback:function ($$v) {_vm.$set(_vm.formData, "canChange", $$v)},expression:"formData.canChange"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("否")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("是")])],1)],1),_c('el-form-item',{attrs:{"label":"所属院系","prop":"collegeId"}},[_c('el-select',{attrs:{"disabled":!!_vm.$store.state.login.userInfo.collegeId,"filterable":""},model:{value:(_vm.formData.collegeId),callback:function ($$v) {_vm.$set(_vm.formData, "collegeId", $$v)},expression:"formData.collegeId"}},_vm._l((_vm.collegeList),function(ref){
var collegeName = ref.collegeName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":collegeName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"是否全日制","prop":"collegeId"}},[_c('el-select',{model:{value:(_vm.formData.partTimeFlag),callback:function ($$v) {_vm.$set(_vm.formData, "partTimeFlag", $$v)},expression:"formData.partTimeFlag"}},[_c('el-option',{attrs:{"value":0,"label":"全日制"}}),_c('el-option',{attrs:{"value":1,"label":"非全日制"}})],1)],1),_c('el-form-item',{attrs:{"label":"备注","prop":"remarks"}},[_c('el-input',{model:{value:(_vm.formData.remarks),callback:function ($$v) {_vm.$set(_vm.formData, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.remarks"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }