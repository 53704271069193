<!-- 缴费项目绑定学生 -->
<template>
  <el-dialog
    class="major-bind-campus-dialog"
    :title="`校区管理-${title}专业`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="close"
    width="720px"
  >
    <table-view :show-page="false" v-loading="loading">
      <template #header>
        <div class="font-right mb-1">
          <el-button type="primary" size="small" @click="addRow">添加校区信息</el-button>
        </div>
      </template>
      <el-form ref="form" :model="formData" :rules="rules" label-width="0" style="padding-right: 0;">
        <el-table :data="formData.tableData" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="80" align="center" />
          <el-table-column class-name="campus-form" label="年级" width="160" align="center">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing&&!scope.row.id" :prop="`tableData.${scope.$index}.grade`"
                            :rules="rules.grade">
                <tool-tips-group is-flex tips-content="年级根据“系统设置-系统字典”模块中的年级数据加载。">
                  <el-select v-model="scope.row.grade" filterable size="small">
                    <el-option v-for="{paramValue} in $store.state.systemParam.grade" :key="paramValue"
                               :label="`${paramValue}级`" :value="paramValue" />
                  </el-select>
                </tool-tips-group>
              </el-form-item>
              <span class="campus-name" v-else>{{ scope.row.grade }}级</span>
            </template>
          </el-table-column>
          <el-table-column prop="campus" label="校区" class-name="campus-form" min-width="150">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.campusId`"
                            :rules="rules.campusId">
                <el-select ref="campusRef" v-model="scope.row.campusId" filterable size="small">
                  <el-option v-for="{campusName,id} in campusList" :key="id" :label="campusName" :value="id" />
                </el-select>
              </el-form-item>
              <span class="campus-name" v-else>{{ scope.row.campusName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" align="center">
            <template v-slot="scope">
              <template v-if="scope.row.isEditing">
                <el-button type="primary" size="mini" @click="saveRow(scope.row)">保存</el-button>
                <el-button size="mini" @click="cancelRow(scope.row)">取消</el-button>
              </template>
              <!--  不能设置删除功能,因为没有新增功能  -->
              <el-button v-if="!scope.row.isEditing&&!isEditing" type="primary" size="mini" @click="editRow(scope.row)">
                编辑校区
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </table-view>
    <template #footer>
      <el-button @click="close">关 闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import CampusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'
import { listCampusByMajorIdApi, saveCampusMajorApi } from '@/api/major'

export default {
  name: 'majorBindCampusDialog',
  mixins: [dialog, tableView, CampusSchMajorClasses],
  data () {
    return {
      isEditing: false,
      formData: {
        tableData: []
      },
      tableQuery: {
        grade: new Date().getFullYear()
      },
      rules: {
        campusId: {
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        },
        grade: {
          required: true,
          message: '请选择年级',
          trigger: 'blur'
        }
      }
    }
  },
  mounted () {
    this.getCampusList()
    this.$nextTick(() => {
      if (this.id) {
        this.renderTable(this.id)
      } else {
        this.$message.error('系统出错,请稍后重试！')
        this.close()
      }
    })
  },
  methods: {
    async renderTable (id) {
      try {
        const res = await listCampusByMajorIdApi(id)
        this.formData.tableData = res.data
      } catch (e) {
      }
    },
    // 新增一行
    addRow () {
      if (this.isEditing) {
        this.$message('请先保存已修改的数据，再进行新增操作！')
      } else {
        this.isEditing = true
        const _row = {
          campusId: '',
          majorId: this.id,
          grade: null,
          isEditing: true
        }
        this.formData.tableData.unshift(_row)
      }
    },
    editRow (row) {
      row.isEditing = true
      this.isEditing = true
      this.setInitData()
    },
    cancelRow (row) {
      row.isEditing = false
      this.isEditing = false
      this.renderTable(this.id)
    },
    saveRow (row) {
      this.$refs.form.validate(valid => {
        if (valid) {
          const _duplicateKeys = this.formData.tableData.filter(({ grade }) => grade === row.grade)
          if (_duplicateKeys.length > 1) {
            this.$message.error('该年级已设置校区！')
            return
          }
          const _newCampusName = this.$refs.campusRef.selectedLabel
          const _msg = row.id ? `是否确定将校区从 <b>${row.campusName}</b> 切换到 <b class="primary">${_newCampusName}</b>?<br/><span><b class="danger">请注意：</b>切换校区将同时<b class="danger">清空该专业${row.grade}级所有学生</b>的宿舍信息！</span>` : `是否确认新增<b class="primary">${this.title}</b>专业<b>${row.grade}级</b>的校区信息?（此操作不会影响该年级已设置宿舍的学生）`
          this.$confirm(`<p class="confirm-message">${_msg}</p>`,
            '修改校区', {
              dangerouslyUseHTMLString: true
            }).then(async () => {
            try {
              await saveCampusMajorApi({
                id: row.id,
                campusId: row.campusId,
                majorId: this.id,
                grade: row.grade
              })
              this.$message.success(`${row.id ? '修改' : '新增'}成功!`)
              row.campusName = _newCampusName
              this.cancelRow(row)
            } catch (e) {
            }
          }).catch(() => {
            this.$message.info('已取消修改。')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.major-bind-campus-dialog {
  .el-dialog {
    margin-bottom: 0 !important;

    .el-dialog__body {
      padding-top: 0;

      .el-form {
        .el-form-item {
          margin-bottom: 14px;
        }
      }

      .campus-name {
        vertical-align: 12px;
      }
    }

    .campus-form {
      padding-bottom: 0;
    }
  }
}
</style>
